// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-decolonize-the-art-world-js": () => import("./../src/pages/decolonize-the-art-world.js" /* webpackChunkName: "component---src-pages-decolonize-the-art-world-js" */),
  "component---src-pages-imaginarium-js": () => import("./../src/pages/imaginarium.js" /* webpackChunkName: "component---src-pages-imaginarium-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portal-js": () => import("./../src/pages/portal.js" /* webpackChunkName: "component---src-pages-portal-js" */),
  "component---src-pages-the-lab-js": () => import("./../src/pages/the-lab.js" /* webpackChunkName: "component---src-pages-the-lab-js" */),
  "component---src-templates-imaginarium-js": () => import("./../src/templates/imaginarium.js" /* webpackChunkName: "component---src-templates-imaginarium-js" */),
  "component---src-templates-lesson-js": () => import("./../src/templates/lesson.js" /* webpackChunkName: "component---src-templates-lesson-js" */),
  "component---src-templates-portal-js": () => import("./../src/templates/portal.js" /* webpackChunkName: "component---src-templates-portal-js" */)
}

